<template>
<div class="main gray-bg">
  <van-nav-bar class="top-bar" :title="title" left-arrow @click-left="onClickLeft"/>
  <div class="question-cell" v-for="(sub,key) in list" :key="key">
    <div class="van-ellipsis question-title">{{ sub.title }}</div>
    <div class="question-content">{{ sub.content }}</div>
  </div>
</div>
</template>

<script>
import {toRefs} from "vue";
import "@/style/common.css"
import {NavBar} from "vant";
export default {
  name: "Question",
  props:{
    list:{
      type:Object
    },
    title:{
      type:String
    }
  },
  components:{
    [NavBar.name]:NavBar
  },
  setup(props,context){
    // eslint-disable-next-line no-unused-vars
    const {list,title} = toRefs(props)
    //返回关闭
    const onClickLeft = () => {
      context.emit("closePopup")
    };
    return{
      props,
      context,
      onClickLeft
    }
  }
}
</script>

<style scoped>
.question-cell{
  padding:16px;
  margin:12px 16px;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  text-align: left;
}
.question-title{
  font-size: 15px;
  font-weight: 500;
  color: #1D2129;
  line-height: 22px;
}
.question-content{
  font-size: 12px;
  font-weight: 400;
  color: #86909C;
  line-height:20px;
}
</style>
