<template>
<div class="main gray-bg">
  <van-nav-bar class="top-bar has-bg-bar" title="帮助中心" left-arrow @click-left="onClickLeft"/>
  <div class="bar-bg-head">
    <div class="bar-bg-title">Hi~我是客服小二</div>
    <div class="bar-bg-text">遇到问题请莫慌，常见问题看下方！</div>
  </div>
  <div class="help-center-container">
    <div class="help-cell" v-for="(item,index) in list" :key="index">
      <div class="help-question-title" @click="getQuestionList(item)">
        <van-icon :name="item.icon"/>{{ item.type_name }}
      </div>
      <div class="help-question-list" v-if="item.question">
        <template v-for="(sub,key,index) in item.question" :key="key">
          <div class="van-ellipsis help-col" v-if="index < 2" @click="getQuestionList(item)">{{ sub.title }}</div>
        </template>
      </div>
    </div>
  </div>
  <div class="custom-box flex align-center justify-between">
    <div class="help-question-title">
      <van-icon :name="require('@/assets/images/tool/u-icon.png')"/>在线客服：{{ server.qq }}
    </div>
    <van-tag plain color="#FFA40C" @click="openQQ">联系我</van-tag>
  </div>

  <!--子列表弹窗-->
  <van-popup v-if="questionListVisible" :show="questionListVisible" class="max-popup">
    <Question :title="questionType" :list="questionList" @closePopup="closePopup"></Question>
  </van-popup>
  <!--子列表弹窗-->
</div>
</template>

<script>
import {Tag, Icon, NavBar, Popup} from "vant";
import {ref} from "vue";
import {helpCenter} from "@/api/tool";
import "@/style/common.css"
import {is_mobile} from "@/utils/devices";
import Question from "@/views/tool/Question";
import {exitPage} from "@/utils/callback";
export default {
  name: "HelpCenter",
  components:{
    Question,
    [NavBar.name]:NavBar,
    [Tag.name]:Tag,
    [Popup.name]:Popup,
    [Icon.name]:Icon
  },
  setup(){
    const list = ref([]);
    const server = ref([]);
    const questionListVisible = ref(false)
    const questionType = ref('')
    const questionList = ref({})
    //返回
    const onClickLeft = () => {
      exitPage()
    };
    //初始化数据
    helpCenter().then(({data})=>{
      list.value = data.question_data
      server.value = data.customer_service
    })
    //获取子列表
    const getQuestionList = (item) => {
      questionListVisible.value = true
      questionType.value = item.type_name
      questionList.value = item.question
    }
    //关闭弹窗
    const closePopup = () => {
      questionListVisible.value = false
    }

    const openQQ = ()=>{
      window.location.href = is_mobile() ?
          "mqqwpa://im/chat?sigt=A392221B409A31AA174308B4ED49DFE74DDFB7BAC0DB9F425969942F134CC92EA42E2DCAB9520F517A9DA63F666A5470EAFBD6CAA46190E08767B51FE30F96205B772CFC96647C034494B92B09E6AD347AAB1F85A0ED8FA9E4798E7D632F0E8085319EA21C9BF13A4B80B5FE231928433072F585E53E61BDE3B67C81A4979BBB&chat_type=crm&uin=" + server.value.qq + "&version=1&src_type=web&web_src=1&wpaType=1&assignId=170&QidianKfUin=3009110731&shouldReturnToRoot=NO&env=1" :
          "tencent://message/?Menu=yes&amp;uin=" + server.value.qq + "&amp;Service=58&amp;WPAType=1&amp;SigT=654FB544DF774982A7C54EC67366130E5B4E8BE2B6E0031366DF26CA8825B7A2713299A8212024B5C6A84E34F75F58D25109812B6B3E47E1527C149D1AF08653884B9B52FA28FB04242B37A9CE727B28C4C4F7466A464BCD3A07DD9535743FE5071807A7AF9E1AE905EAFC9D4599136367690628B430DF74DC576662061EC8BA&env=1";
    }
    return {
      list,
      server,
      questionList,
      questionType,
      questionListVisible,
      getQuestionList,
      onClickLeft,
      openQQ,
      closePopup,
    }
  }
}
</script>

<style scoped>
.top-bar{
  z-index: 999;
}
.top-bar,.top-bar :deep(.van-nav-bar__content){
  background:none;
}
.bar-bg-head{
  position:absolute;
  z-index:5;
  width: 100%;
  top:0px;
  color: #fff;
  padding: 44px 16px 57px 120px;
  height:171px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-flow: column;
  text-align: left;
  background: url("../../assets/images/tool/help-head.png") bottom center no-repeat;
  background-size: 100% auto;
}
.bar-bg-title{
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 21px;
}
.bar-bg-text{
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 21px;
  margin-top:4px;
}
.help-center-container{
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin:0px 16px;
  position: relative;
  z-index:8;
  margin-top:70px;
  text-align: left;
  color: #4E5969;
  padding:10px;
}
.help-question-title{
  font-size: 16px;
  font-weight: 500;
  color: #4E5969;
  line-height: 22px;
  display: flex;
  align-items: center;
  padding:6px;
}
.help-question-title .van-icon{
  margin-right:5px;
}
.help-question-list{
  padding:6px 0px;
  font-size: 14px;
  font-weight: 400;
  color: #4E5969;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.help-col{
  padding:0px 6px;
  width:50%;
}
.custom-box{
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin:12px 16px;
  padding:6px;
}
.custom-box .van-tag{
  border-right: 5px;
  width:46px;
  height:22px;
  margin-right:6px;
  justify-content: center;
  cursor: pointer;
}
</style>
